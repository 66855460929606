import {
  StatsSectionStyled,
  StatsSubHeadingStyled,
  StatsDescriptionStyled,
  StatsStyled,
  ThumbsUpIconStyled,
  DirectoryCheckListStyled,
  GuranteeBadgeStyled
} from './styled/StatsSectionStyled';

const StatsSection = () => {
  return (
    <StatsSectionStyled>
      <StatsStyled>
        <GuranteeBadgeStyled />
        <StatsSubHeadingStyled>
          We guarantee
        </StatsSubHeadingStyled>
        <StatsDescriptionStyled>
          Get your job done right
        </StatsDescriptionStyled>
      </StatsStyled>
      <StatsStyled>
        <ThumbsUpIconStyled />
        <StatsSubHeadingStyled>
          Verified businesses
        </StatsSubHeadingStyled>
        <StatsDescriptionStyled>
          Only hire the best
        </StatsDescriptionStyled>
      </StatsStyled>
      <StatsStyled>
        <DirectoryCheckListStyled />
        <StatsSubHeadingStyled>
          No Obligations
        </StatsSubHeadingStyled>
        <StatsDescriptionStyled>
          Free to request and free to hire
        </StatsDescriptionStyled>
      </StatsStyled>
    </StatsSectionStyled>
  );
};

export default StatsSection;
