// #region types
import type { NextPage } from 'next';
// #endregion types

// #region utils/lib/config
import { publicRuntimeConfig } from 'lib/utils/Environment';
import { getCloudFrontImageUrl } from 'shared/utils/cloudFrontImageResize';
// #endregion utils/lib/config

// #region components
import RecentReviews from '@oneflare/flarekit/lib/components/RecentReviews';
import PageHeader from 'shared/components/PageHeader/PageHeader';
import Banner from 'shared/components/Banner/Banner';

// wedding components
import HeadContent from '@shared-wedding-components/HeadContent';
import HeroDropDown from '@shared-wedding-components/HeroDropDown';
import StatsSection from '@shared-wedding-components/StatsSection';
import WeddingFooter from '@shared-wedding-components/WeddingFooter';
import HowItWorksSection from '@shared-wedding-components/HowItWorksSection';
// #endregion components

const resize = {
  width: 40,
  height: 40,
  fit: 'cover'
};

const reviews = [
  {
    id: 107020,
    score: 5,
    notes: 'We set out to have a ceremony that was personal, simple yet interactive. We wanted a celebrant that made our day fun and put a smile on the faces of our 100 or so guests.\r\nCate Absolutely exceeded our expectations there. The real highlight though was the lead up to the wedding. Cate asks the questions about you to form a story about your love and presents this to the congregation to set the scene. She is so calming and speaks of you as if she has known you a lifetime. If we did it all again, we’d chose Cate and not change a thing. Thanks for everything, you’re a big part of our happy puzzle.',
    byName: 'Claire',
    avatarPath: getCloudFrontImageUrl({
      key: 'sample_avatars/167.jpg',
      bucket: 'oneflare-production',
      edits: { resize }
    }),
    for: {
      business: {
        name: 'Celebrations By Cate',
        profileUrl: '/business/celebrations-by-cate'
      }
    }
  },
  {
    id: 105353,
    score: 5,
    notes: 'I wanted a wedding photographer who would add -and not impose- on our celebrations. Someone who could intermingle with our guests, be playful, and extract and capture all those candid in-between moments of love and delight. \r\nThe images Carly captured were precisely this! Full of warmth and heart, and read like a beautiful story book of our special day. It was truly as though she was just one of the guests. A joy to have on board - Thank you Carly!',
    byName: 'Milz',
    avatarPath: getCloudFrontImageUrl({
      key: 'sample_avatars/164.jpg',
      bucket: 'oneflare-production',
      edits: { resize }
    }),
    for: {
      business: {
        name: 'Carlita Sari Photo',
        profileUrl: '/business/carlita-sari-photo'
      }
    }
  }
];

const WeddingHomePage: NextPage = () => {
  const { SITE_URL } = publicRuntimeConfig;
  return (
    <>
      <HeadContent
        pageUrl={SITE_URL}
      />
      <PageHeader />
      <HeroDropDown />
      <Banner
        group="Wedding.com.au"
        url="https://cdn.oneflare.com/static/wedding/banner.png"
      />
      <HowItWorksSection
        sectionHeader="How it works"
      />
      <RecentReviews
        circleAvatar
        fillStar="#ff975b"
        title="Who uses wedding.com.au"
        reviews={reviews}
      />
      <StatsSection />
      <WeddingFooter />
    </>
  );
};

export default WeddingHomePage;
