import { styled } from 'styled-components';
import H2 from '@oneflare/flarekit/lib/components/H2';
import media from '@oneflare/flarekit/lib/libs/media';
import Paragraph from '@oneflare/flarekit/lib/components/Paragraph';
import ChatBubble from '@oneflare/flarekit/lib/components/icons/ChatBubble';
import DocumentCheckList from '@oneflare/flarekit/lib/components/icons/DocumentCheckList';
import UpdateProfile from '@oneflare/flarekit/lib/components/icons/UpdateProfile';

export const HowItWorksSectionStyled = styled.section`
  background-color: ${({ theme }) => theme.color.white};
  margin: 40px 20px 0;
  ${media.mdUp`
    padding: 64px 40px;
    margin: 0;
  `}
  ${media.lgUp`
    margin: 0 auto;
    padding: 80px 40px;
    max-width: 1140px;
  `}
`;

export const HowItWorksHeaderStyled = styled(H2)`
  color: ${({ theme }) => theme.color.tertiary};
  font-family: ${({ theme }) => theme.font.secondaryBold};
  font-size: 28px;
  line-height: 42px;
  text-align: center;
  display: none;
  margin-bottom: 24px;

  ${media.smUp`
    display: block;
  `}
  ${media.mdUp`
    margin-bottom: 0;
  `}
`;

export const StepWrapperStyled = styled.div`
  display: flex;
  flex-direction: column;
  ${media.mdUp`
    flex-direction: row;
    margin-top: 40px;
  `}
  ${media.lgUp`
    margin-top: 56px;
  `}
`;

export const StepStyled = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: 33px;

  &:last-child {
    margin-bottom: 40px;
  }

  ${media.mdUp`
    align-items: center;
    flex-direction: column;
    flex: 1 0 0;
    justify-content: start;
    padding-right: 64px;
    margin: 0;
    &:last-child {
      padding-right: 0;
    }
    &:last-child {
      margin-bottom: 0;
    }
    text-align: center;
  `}

  ${media.lgUp`
    margin: 0 auto;
  `}
`;

export const StepDescriptionStyled = styled(Paragraph)`
  font-family: ${({ theme }) => theme.font.primary};
  font-size: 16px;
  line-height: 24px;
  margin-bottom: 0;
  ${media.mdUp`
    line-height: 27px;
    margin-top: 16px;
  `}
`;

export const ChatBubbleIconStyled = styled(ChatBubble).attrs(({ theme }) => ({
  fill: theme.color.secondary,
  width: '40px',
  height: '41px'
}))`
  margin-right: 24px;
  ${media.mdUp`
    svg {
      width: 64px;
      height: 65px;
    }
    margin-right: 0;
  `}
`;

export const DocumentCheckListIconStyled = styled(DocumentCheckList).attrs(({ theme }) => ({
  fill: theme.color.secondary,
  width: '30px',
  height: '39px'
}))`
  padding: 0 5px;
  margin-right: 24px;
  ${media.mdUp`
    svg {
      width: 50px;
      height: 64px;
    }
    margin-right: 0;
  `}
`;

export const UpdateProfileIconStyled = styled(UpdateProfile).attrs(({ theme }) => ({
  fill: theme.color.secondary,
  width: '40px',
  height: '39px'
}))`
  margin-right: 24px;
  ${media.mdUp`
    svg {
      width: 64px;
      height: 62px;
    }
    margin-right: 0;
  `}
`;
