import PropTypes from 'prop-types';
import { CategoryObjectType } from 'shared/types';
import {
  HowItWorksSectionStyled,
  HowItWorksHeaderStyled,
  StepStyled,
  StepWrapperStyled,
  StepDescriptionStyled,
  ChatBubbleIconStyled,
  DocumentCheckListIconStyled,
  UpdateProfileIconStyled
} from './styled/HowItWorksSectionStyled';
import { publicRuntimeConfig } from 'lib/utils/Environment';

const propTypes = {
  category: CategoryObjectType,
  className: PropTypes.string,
  sectionHeader: PropTypes.string
};

const defaultProps = {
  category: {
    singular: 'wedding provider',
    plural: 'wedding providers'
  },
  sectionHeader: '',
  className: null
};

const HowItWorksSection = ({ category, sectionHeader, className }) => {
  return (
    <HowItWorksSectionStyled className={className}>
      <HowItWorksHeaderStyled>
        {sectionHeader || `Find the right ${category.singular.toLowerCase()} for your big day`}
      </HowItWorksHeaderStyled>
      <StepWrapperStyled>
        <StepStyled>
          <DocumentCheckListIconStyled />
          <StepDescriptionStyled>
            {`Compare up to ${publicRuntimeConfig.SITE_DEFAULT_QUOTES} quotes from verified businesses`}
          </StepDescriptionStyled>
        </StepStyled>
        <StepStyled>
          <ChatBubbleIconStyled />
          <StepDescriptionStyled>
            {`Browse & connect with ${category.plural.toLowerCase()} available in your wedding location`}
          </StepDescriptionStyled>
        </StepStyled>
        <StepStyled>
          <UpdateProfileIconStyled />
          <StepDescriptionStyled>
            View business profiles and read star-rated reviews
          </StepDescriptionStyled>
        </StepStyled>
      </StepWrapperStyled>
    </HowItWorksSectionStyled>
  );
};

HowItWorksSection.propTypes = propTypes;
HowItWorksSection.defaultProps = defaultProps;

export default HowItWorksSection;
